import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography } from "@material-ui/core";
import { Service as ServiceShape } from "./shapes";
import Service from "./service";

const useStyles = makeStyles(theme => ({
  services: {
    backgroundColor: "#e9eef7",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  servicesTitle: {
    textAlign: "center",
  },
  items: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
  },
}));

const Services = ({ services }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <section className={classes.services}>
      <Typography variant="h3" className={classes.servicesTitle} gutterBottom>
        {t("home.services.title")}
      </Typography>
      <div className={classes.items}>
        {services.map(service => (
          <Service key={service.frontmatter.title} service={service} />
        ))}
      </div>
    </section>
  );
};

Services.propTypes = {
  services: PropTypes.arrayOf(ServiceShape),
};

export default Services;
