import React from "react";
import { graphql } from "gatsby";
import { Button, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ForwardedLink from "../shared/components/forwarded-link";
import Services from "../components/index/services";
import ScrollDownIcon from "../components/scroll-down-icon";

const useStyles = makeStyles(theme => {
  return {
    intro: {
      height: "calc(100vh - 50px)",
      color: "white",
      display: "grid",
    },
    scrollDownWrapper: {
      position: "absolute",
      bottom: "4em",
      [theme.breakpoints.up("sm")]: {
        bottom: "1em",
      },
      left: "50%",
      transform: "translateX(-50%)",
    },
    introContent: {
      gridArea: "1/1",
      position: "relative",
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      width: "90%",
      [theme.breakpoints.up("md")]: {
        width: "50%",
      },
      [theme.breakpoints.up("sm")]: {
        width: "50%",
      },
    },
    aboutOwner: {
      padding: theme.spacing(4),
      [theme.breakpoints.up("md")]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        "& > *": {
          flexGrow: 1,
        },
      },
    },
    aboutOwnerPhoto: {
      "& > div": {
        textAlign: "center",
        margin: "0 auto",
      },
      "& img": {
        borderRadius: "50%",
      },
    },
    aboutOwnerContent: {},
    getStarted: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      textAlign: "center",
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  };
});

const IndexPage = ({ data }) => {
  const services = data?.services?.services;
  const classes = useStyles();
  const { t } = useTranslation();
  const aboutOwnerRef = React.useRef();

  return (
    <Layout noGutterBottom noGutterTop>
      <SEO title="Home" />
      <section className={classes.intro}>
        <StaticImage
          style={{
            gridArea: "1/1",
          }}
          layout="fullWidth"
          aspectRatio={1.75 / 1}
          alt="" // This is a presentational image, so the alt should be an empty string
          src={"../images/cover1.jpg"}
          formats={["auto", "webp", "avif"]}
          quality={80}
        />
        <div className={classes.introContent}>
          <div>
            <Typography variant="h3">{t("home.intro.title")}</Typography>
            <br />
            <Typography variant="body1">{t("home.intro.details")}</Typography>
            <br />
            <Button
              variant="contained"
              color="secondary"
              component={ForwardedLink}
              to="/about"
            >
              {t("home.intro.learn-more")}
            </Button>
          </div>
        </div>
        <div className={classes.scrollDownWrapper}>
          <ScrollDownIcon
            onClick={() =>
              aboutOwnerRef.current.scrollIntoView({
                behavior: "smooth",
              })
            }
          />
        </div>
      </section>

      <section className={classes.aboutOwner} ref={aboutOwnerRef}>
        <div className={classes.aboutOwnerPhoto}>
          <StaticImage
            src="../images/rubtsova1.jpeg"
            layout="fixed"
            height={200}
            quality={90}
            alt="Rubtsova"
          />
        </div>
        <div className={classes.aboutOwnerContent}>
          <Typography variant="h4">{t("home.owner.title")}</Typography>
          <Typography variant="body1">{t("home.owner.details")}</Typography>
        </div>
      </section>

      {services && (
        <section>
          <Services services={services} />
        </section>
      )}

      <section className={classes.getStarted}>
        <Typography variant="h4" gutterBottom>
          {t("home.get-started.title")}
        </Typography>

        <Button
          color="secondary"
          size="large"
          variant="contained"
          component={ForwardedLink}
          to="/contact-us"
        >
          {t("home.get-started.contact-us")}
        </Button>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery($language: String!) {
    services: allMdx(
      sort: { fields: frontmatter___date, order: ASC }
      filter: {
        fileAbsolutePath: { regex: "/services/" }
        frontmatter: { language: { eq: $language } }
      }
    ) {
      services: nodes {
        frontmatter {
          title
          description
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;
