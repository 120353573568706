import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ForwardedLink from "../../shared/components/forwarded-link";
import { useTranslation } from "react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Service as ServiceShape } from "./shapes";

const useStyles = makeStyles(theme => ({
  service: {
    width: "300px",
    margin: [theme.spacing(2)],
    textAlign: "center",
  },
  media: {
    marginBottom: theme.spacing(2),
    height: "175px",
    textAlign: "center",
    "& img": {
      height: "175px",
    },
  },
  content: {},
  learnMore: {
    "& button": {
      textAlign: "center",
      margin: "0 auto",
    },
    margin: theme.spacing(2),
  },
}));

const Service = ({
  service: {
    frontmatter: { title, description, icon },
    slug,
  },
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const image = getImage(icon);
  return (
    <div className={classes.service}>
      {image && (
        <div className={classes.media}>
          <GatsbyImage image={image} alt={title} />
        </div>
      )}

      <div className={classes.content}>
        <Typography gutterBottom variant="h5">
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          align="justify"
        >
          {description}
        </Typography>
      </div>

      <div className={classes.learnMore}>
        <Button
          color="secondary"
          variant="contained"
          component={ForwardedLink}
          to={`/${slug.split(".")[0]}`}
        >
          {t("home.services.learn-more")}
        </Button>
      </div>
    </div>
  );
};

Service.propTypes = {
  service: ServiceShape.isRequired,
};

export default Service;
