import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { makeStyles, IconButton } from "@material-ui/core";

import ExpandMoreIcon from "../icons/expand-more-icon";

const useStyles = makeStyles(() => ({
  scrollDownStage: {
    display: "flex",
    height: "100px",
    width: "100%",
  },
  scrollDown: {
    alignSelf: "flex-end",
    animationDuration: "3s",
    animationIterationCount: "infinite",
    transformOrigin: "bottom",
    animationName: "$scrollDownBounce",
    animationTimingFunction: "ease",
    color: "white",

    "& *": {
      fontSize: "150%",
    },
  },
  "@keyframes scrollDownBounce": {
    "0%": { transform: "translateY(0)" },
    "7%": { transform: "translateY(0)" },
    "22%": { transform: "translateY(-10px)" },
    "37%": { transform: "translateY(0)" },
    "43%": { transform: "translateY(-5px)" },
    "48%": { transform: "translateY(0)" },
    "100%": { transform: "translateY(0)" },
  },
}));

const ScrollDownIcon = ({ onClick, className }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.scrollDownStage, className)}>
      <IconButton
        aria-label="scroll down"
        onClick={onClick}
        size="medium"
        className={classes.scrollDown}
      >
        <ExpandMoreIcon />
      </IconButton>
    </div>
  );
};

ScrollDownIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ScrollDownIcon.defaultProps = {
  className: "",
};

export default ScrollDownIcon;
