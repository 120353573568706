import PropTypes from "prop-types";

export const Service = PropTypes.shape({
  frontmatter: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.shape({}),
  }),
  slug: PropTypes.string.isRequired,
});
